/* cyrillic-ext */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZMkidh18S0xR41YDw.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZMkido18S0xR41YDw.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZMkidg18S0xR41YDw.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZMkidv18S0xR41YDw.woff2) format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZMkidj18S0xR41YDw.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZMkidi18S0xR41YDw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZMkids18S0xR41.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7qsDJB9cme_xc.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7jsDJB9cme_xc.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7rsDJB9cme_xc.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7ksDJB9cme_xc.woff2) format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7osDJB9cme_xc.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7psDJB9cme_xc.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7nsDJB9cme.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwmhdu3cOWxy40.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwkxdu3cOWxy40.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwmxdu3cOWxy40.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwlBdu3cOWxy40.woff2) format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwmBdu3cOWxy40.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwmRdu3cOWxy40.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwlxdu3cOWxw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNa7lujVj9_mf.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qPK7lujVj9_mf.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNK7lujVj9_mf.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qO67lujVj9_mf.woff2) format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qN67lujVj9_mf.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNq7lujVj9_mf.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7lujVj9w.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  body {
    -webkit-text-size-adjust: none;
  }
  mark {
    background-color: transparent;
    color: inherit;
  }
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  input[type="text"],
  input[type="email"],
  select,
  textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
  }
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  body {
    line-height: 1;
    min-height: var(--viewport-height);
    min-width: 320px;
    overflow-x: hidden;
    word-wrap: break-word;
    background-color: #ffffff;
  }
  :root {
    --background-height: 100vh;
    --site-language-alignment: left;
    --site-language-direction: ltr;
    --site-language-flex-alignment: flex-start;
    --viewport-height: 100vh;
  }
  html {
    font-size: 18pt;
  }
  u {
    text-decoration: underline;
  }
  strong {
    color: inherit;
    font-weight: bolder;
  }
  em {
    font-style: italic;
  }
  code {
    background-color: rgba(144, 144, 144, 0.25);
    border-radius: 0.25em;
    font-family: "Lucida Console", "Courier New", monospace;
    font-size: 0.9em;
    font-weight: normal;
    letter-spacing: 0;
    margin: 0 0.25em;
    padding: 0.25em 0.5em;
    text-indent: 0;
  }
  mark {
    background-color: rgba(144, 144, 144, 0.25);
  }
  s {
    text-decoration: line-through;
  }
  sub {
    font-size: smaller;
    vertical-align: sub;
  }
  sup {
    font-size: smaller;
    vertical-align: super;
  }
  a {
    color: inherit;
    text-decoration: underline;
    transition: color 0.25s ease;
  }
  #wrapper {
    -webkit-overflow-scrolling: touch;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: var(--viewport-height);
    overflow: hidden;
    position: relative;
    z-index: 2;
  }
  #main {
    --alignment: center;
    --flex-alignment: center;
    --border-radius-tl: 0;
    --border-radius-tr: 0;
    --border-radius-br: 0;
    --border-radius-bl: 0;
    align-items: center;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    max-width: 100%;
    position: relative;
    text-align: var(--alignment);
    z-index: 1;
    transition: opacity 1s ease-in-out 0s;
  }
  #main > .inner {
    --padding-horizontal: 1rem;
    --padding-vertical: 1.25rem;
    --spacing: 1.125rem;
    --width: 37rem;
    border-radius: var(--border-radius-tl) var(--border-radius-tr)
      var(--border-radius-br) var(--border-radius-bl);
    max-width: 100%;
    position: relative;
    width: var(--width);
    z-index: 1;
    padding: var(--padding-vertical) var(--padding-horizontal);
  }
  #main > .inner > * {
    margin-top: var(--spacing);
    margin-bottom: var(--spacing);
  }
  #main > .inner > :first-child {
    margin-top: 0 !important;
  }
  #main > .inner > :last-child {
    margin-bottom: 0 !important;
  }
  #main > .inner > .full {
    margin-left: calc(var(--padding-horizontal) * -1);
    max-width: calc(100% + calc(var(--padding-horizontal) * 2) + 0.4725px);
    width: calc(100% + calc(var(--padding-horizontal) * 2) + 0.4725px);
  }
  #main > .inner > .full:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    margin-top: calc(var(--padding-vertical) * -1) !important;
  }
  #main > .inner > .full:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    margin-bottom: calc(var(--padding-vertical) * -1) !important;
  }
  #main > .inner > .full.screen {
    border-radius: 0 !important;
    max-width: 100vw;
    position: relative;
    width: 100vw;
    left: 50%;
    margin-left: -50vw;
    right: auto;
  }
  body.is-loading #main {
    opacity: 0;
  }
  body.is-instant #main,
  body.is-instant #main > .inner > *,
  body.is-instant #main > .inner > section > * {
    transition: none !important;
  }
  body.is-instant:after {
    display: none !important;
    transition: none !important;
  }
  #footer {
    margin-top: 2rem !important;
  }
  h1,
  h2,
  h3,
  p {
    direction: var(--site-language-direction);
    position: relative;
  }
  h1 span.p,
  h2 span.p,
  h3 span.p,
  p span.p {
    display: block;
    position: relative;
  }
  h1 span[style],
  h2 span[style],
  h3 span[style],
  p span[style],
  h1 strong,
  h2 strong,
  h3 strong,
  p strong,
  h1 a,
  h2 a,
  h3 a,
  p a,
  h1 code,
  h2 code,
  h3 code,
  p code,
  h1 mark,
  h2 mark,
  h3 mark,
  p mark {
    -webkit-text-fill-color: currentcolor;
  }
  #text01 {
    text-transform: uppercase;
    color: #474747;
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 0.4rem;
    width: calc(100% + 0.4rem);
    font-size: 1em;
    line-height: 1.5;
    font-weight: 400;
  }
  #text01 a {
    text-decoration: underline;
  }
  #text01 a:hover {
    text-decoration: none;
  }
  #text01 span.p:nth-child(n + 2) {
    margin-top: 1rem;
  }
  #text07 {
    text-align: left;
    color: #474747;
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 0.025rem;
    width: calc(100% + 0.025rem);
    font-size: 0.75em;
    line-height: 1.875;
    font-weight: 300;
  }
  #text07 a {
    text-decoration: underline;
  }
  #text07 a:hover {
    text-decoration: none;
  }
  #text07 span.p:nth-child(n + 2) {
    margin-top: 1rem;
  }
  hr {
    border: 0;
    padding: 0;
    position: relative;
    width: 100%;
  }
  hr:before {
    content: "";
    display: inline-block;
    max-width: 100%;
    vertical-align: middle;
  }
  #divider03:not(:first-child) {
    margin-top: 0.875rem !important;
  }
  #divider03:not(:last-child) {
    margin-bottom: 0.875rem !important;
  }
  #divider03:before {
    width: 100%;
    border-top: solid 1px #cccccc;
    height: 1px;
    margin-top: -0.5px;
  }
  @media (max-width: 1920px) {
  }
  @media (max-width: 1680px) {
    html {
      font-size: 13pt;
    }
  }
  @media (max-width: 1280px) {
    html {
      font-size: 13pt;
    }
  }
  @media (max-width: 1024px) {
  }
  @media (max-width: 980px) {
    html {
      font-size: 11pt;
    }
  }
  @media (max-width: 736px) {
    html {
      font-size: 11pt;
    }
    #main > .inner {
      --padding-horizontal: 1rem;
      --padding-vertical: 1.25rem;
      --spacing: 1.125rem;
    }
    #footer {
      margin-top: 1.5rem !important;
    }
    #text01 {
      letter-spacing: 0.35rem;
      width: calc(100% + 0.35rem);
      font-size: 1em;
      line-height: 1.5;
    }
    #text07 {
      letter-spacing: 0.021875rem;
      width: calc(100% + 0.021875rem);
      font-size: 0.75em;
      line-height: 1.875;
    }
    #divider03:not(:first-child) {
      margin-top: 0.875rem !important;
    }
    #divider03:not(:last-child) {
      margin-bottom: 0.875rem !important;
    }
    #divider03:before {
      width: 100rem;
    }
  }
  @media (max-width: 480px) {
    #main > .inner {
      --spacing: 0.984375rem;
    }
  }
  @media (max-width: 360px) {
    #main > .inner {
      --padding-horizontal: 0.75rem;
      --padding-vertical: 0.9375rem;
      --spacing: 0.84375rem;
    }
    #text01 {
      font-size: 1em;
    }
    #text07 {
      font-size: 0.75em;
    }
  }